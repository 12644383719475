import React, { useContext, useState } from "react";
import { Button, Box, Typography, useMediaQuery } from "@mui/material";
import { BillContext } from "../BillContext";
import axios from "axios";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { kstheme } from "../../../../theme/theme";
import {Dialog,DialogActions,DialogContent,DialogTitle,DialogContentText} from "@mui/material";

import { APIConfig } from "../../../../auth/config";

function PaymentMethod({ paymentSetUpDone, paymentSetUpDate, ssnSubmited }) {


  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0]; // yyyy-mm-dd
    const time = date.toTimeString().split(' ')[0]; // hh:mm:ss
    return `${formattedDate} ${time}`;
  }
  
  const [open, setOpen] = useState(false);


  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));
  const route = useNavigate()
  const location = useLocation();

  const { isNewUser, userCode, ssn } = useContext(BillContext);
  const [originalUrl, setOriginalUrl] = useState("");
  const [returnedFromPayment, setReturnedFromPayment] = useState(false);


  const BASE_URL = APIConfig.BASE_URL + "/ks-bill/api/v1";
  const CANCEL_URL = "http://localhost:3000/cancel";
  const currentPath = window.location.pathname;
  const successPath = currentPath.replace(/[^/]*$/, 'billing');
  const SUCCESS_URL = `${window.location.origin}${successPath}`;
  //const SUCCESS_URL = window.location.origin + successPath;

  const PAYMENT_URL = `${BASE_URL}/payment/payment-setup?usercode=${userCode}&ssn=123456&successUrl=${SUCCESS_URL}&cancelUrl=${CANCEL_URL}`;
  let data;
  const fetchCheckoutSession = async () => {

    try {
      setOriginalUrl(window.location.href);
      const response = await axios.get(PAYMENT_URL);
      data = response.data;
      window.location.href = data.url;
      setReturnedFromPayment(true);
    } catch (error) {
      // Handle error
    }
  };


  const parseDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
  };
  const parsedDate = parseDate(paymentSetUpDate);

  const cancelPayment = () => {
    // Implementtion Pending
  };


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmCancel = () => {
    setOpen(false);
    // Add your cancel payment logic here
    console.log("Payment method cancelled");
  };


  if (paymentSetUpDone === true) {
    return (
      <>
        <Box maxWidth="100%" margin="auto" textAlign="left">
          <Typography variant="h4" gutterBottom style={{ fontFamily: 'DMSans-Regular', fontWeight: 700, fontSize: '24px', lineHeight: '36px' }}>
            Your Automatic Payment Setup is Completed
          </Typography>
          {/* <Typography variant="body1" marginBottom={2} style={{ fontFamily: 'DMSans-Regular', fontWeight: 400, fontSize: '14px', lineHeight: '18px' }}>
            We are pleased to inform you that your automatic payment subscription was successfully completed on  {parsedDate.toString()}

          </Typography> */}
          <Typography variant="body1" marginBottom={2} style={{ fontFamily: 'DMSans-Regular', fontWeight: 400, fontSize: '14px', lineHeight: '18px' }}>
            We are pleased to inform you that your automatic payment subscription was successfully completed on
          </Typography>

          <Typography variant="body1" marginBottom={2} style={{ fontFamily: 'DMSans-Regular', fontWeight: 400, fontSize: '14px', lineHeight: '18px' }}>
          {formatDate(parsedDate)}.
          </Typography >
          <Box marginTop={3} gap={2} display="flex">
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Cancel Set up Auto-Pay
            </Button>
            <Button
                  variant="outlined"
                  sx={{
                    color: "primary.main",
                    borderColor: "primary.main",
                    '&:hover': {
                      backgroundColor: "primary.light",
                      borderColor: "primary.dark",
                    },
                  }}
                  onClick={fetchCheckoutSession}
                >
                  Update Auto-Pay
            </Button>
          </Box>

          <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Cancel Auto-Pay</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel the automatic payment setup?  Pleae contact our support team.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={handleConfirmCancel} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
        </Box>
      </>
    );
  } else if (ssn != null || ssnSubmited === true) {

    if (isNewUser !== null && isNewUser !== "No") {
      return (
        <>
          <Typography variant="h4" gutterBottom>
            Set your payments
          </Typography>
          <Typography variant="body1" paragraph>
            Streamline your payments with hassle-free automatic payments.
          </Typography>
          <Button variant="contained" color="primary" onClick={fetchCheckoutSession}>
            Setup Auto-pay
          </Button>
        </>
      );
    }
    else {
      return null;
    }
  }
  else {
    return null;
  }
}

export default PaymentMethod;
