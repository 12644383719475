import React from 'react';
import { Analytics, CreditScore, CurrencyExchangeOutlined, ErrorOutlineOutlined, ForwardToInboxOutlined, HelpOutline, Leaderboard, SettingsOutlined } from '@mui/icons-material';
import { ROLE_LIST } from '../../constants/constatnt-variable';
import FolderIcon from '@mui/icons-material/Folder';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PreviewIcon from '@mui/icons-material/Preview';

const allRoleWMLP = [ROLE_LIST.broker, ROLE_LIST.mloOwner, ROLE_LIST.borrower, ROLE_LIST.mlo]

const allRole = [ROLE_LIST.broker, ROLE_LIST.mloOwner, ROLE_LIST.borrower, ROLE_LIST.mlo, ROLE_LIST.mlp]

const allRoleWithAdmin = [ROLE_LIST.broker, ROLE_LIST.mloOwner, ROLE_LIST.borrower, ROLE_LIST.mlo, ROLE_LIST.mlp, ROLE_LIST.admin]

const internalRole = [ROLE_LIST.broker, ROLE_LIST.mloOwner, ROLE_LIST.mlo]

export const menuItems = [
    {
        id: 1,
        label: 'Loans',
        icon: CurrencyExchangeOutlined,
        url: 'loan-application',
        roles: allRole
    },
    {
        id: 2,
        label: 'Invitations',
        icon: ForwardToInboxOutlined,
        url: 'invitation-list',
        roles: allRoleWMLP
    },
    {
        id: 3,
        label: 'Leads',
        icon: Leaderboard,
        url: 'leads',
        roles: internalRole
    },
    {
        id: 4,
        label: 'Support',
        icon: HelpOutline,
        url: 'support',
        roles: [ROLE_LIST.admin]
    },
    {
        id: 5,
        label: 'Demo Request',
        icon: ErrorOutlineOutlined,
        url: 'demo-request',
        roles: [ROLE_LIST.admin]
    },
    {
        id: 6,
        label: 'Team Invitation',
        icon: ErrorOutlineOutlined,
        url: 'team-invitation',
        roles: [ROLE_LIST.mlp]
    },
    {
        id: 7,
        label: 'Document Library',
        icon: FolderIcon,
        url: 'doc-library',
        roles: [ROLE_LIST.admin],
        submenu: [
            {
                id: 7,
                label: 'Task Type',
                url: 'task-type',
                icon: DescriptionIcon,
            },
            {
                id: 8,
                label: 'Task Document',
                // url: 'task-document',
                url: 'doc-library',
                icon: DescriptionIcon,
            },
        ],
    },
    {
        id: 8,
        label: 'Analytics',
        icon: Analytics,
        url: 'analytics',
        roles: [ROLE_LIST.borrower, ROLE_LIST.mlp, ROLE_LIST.mlo, ROLE_LIST.broker, ROLE_LIST.admin]
    },
];

export const menuItemsDown = [
    {
        id: 1,
        label: 'Credit Report',
        icon: CreditScore,
        url: 'credco-credentials',
        roles: [ROLE_LIST.broker, ROLE_LIST.mloOwner, ROLE_LIST.mlo]

    },
    {
        id: 2,
        label: 'Settings',
        icon: SettingsOutlined,
        url: 'settings',
        roles: allRoleWithAdmin
    },
];